@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body,
html,
#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
    color: black;
}

.sortable-active {
    z-index: 100000;
}
body {
}
body .MuiTableCell-head {
    font-weight: 600 !important;
    /* padding: 10px; */
    background-color: gainsboro;
}
body .MuiTableCell-root {
    /* padding: 0px 10px; */
}
